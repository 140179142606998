    import { getUser } from "../hooks/user.actions";
    import { useState, useEffect, useRef } from "react";
    import { useUserActions } from "../hooks/user.actions";
    import axios from "axios";

    import { createRoot } from "react-dom/client";
    import { Container, Button, Alert } from "react-bootstrap";
    import { CSSTransition } from "react-transition-group";

    function CommentsPanel(props) {
    const { showComments, video_id, showModal, modalMessage, modalTitle } = props;
    const user = getUser();

    const [showMessage, setShowMessage] = useState(false);
    const nodeRef = useRef(null);
    const userActions = useUserActions();
    const [comment, setComment] = useState(null);
    const [videoComments, setVideoComments] = useState([]);
    const [commentUnderEditing, setCommentUnderEditing] = useState("");

    const onCommentSubmit = () => {
        const postService = userActions.commentoAxios();
        if (!postService) {
        showModal(true);
        modalTitle("Not logged in");
        modalMessage(" comment on a video");
        return;
        }
        if (!comment) {
        return;
        }
        postService
        .post(process.env.REACT_APP_BASE_URL + `/comment/comment/${video_id}`, {
            body: comment.trim(),
        })
        .then((res) => {
            setVideoComments([
            ...videoComments,
            {
                id: res.data.id,
                body: res.data.body,
                user: res.data.user,
                username: res.data.username,
            },
            ]);
            setComment("");
        })
        .catch((err) => {
            if (err.response["data"] === "Email not verified") {
            modalTitle("Email not verified!");
            modalMessage(
                "verify your email to be able to comment on a video. we have sent you a verification email. check your spam if you do not see it in inbox."
            );
            showModal(true);
            }
        });
    };

    const handleDeleteComment = (commentID) => {
        const postService = userActions.commentoAxios();
        if (!postService) {
        console.log("Must be logged in to delete a comment");
        showModal(true);
        modalMessage(" delete a comment");
        return;
        }
        postService
        .delete(process.env.REACT_APP_BASE_URL + `/comment/delete/${commentID}`)
        .then((res) => {
            const commentsListAfterDel = videoComments.filter(
            (comment) => comment.id !== commentID
            );
            setVideoComments([...commentsListAfterDel]);
        })
        .catch((err) => console.log(err));
    };

    useEffect(() => {
        axios
        .get(process.env.REACT_APP_BASE_URL + `/comment/get_comments/${video_id}`)
        .then((res) => {
            setVideoComments(res.data.results);
        });
    }, []);

    return (
        <Container>
        <CSSTransition
            in={showComments}
            nodeRef={nodeRef}
            timeout={300}
            classNames="alert"
            unmountOnExit
        >
            <div
            id="comments-list"
            style={{
                position: "absolute",
                top: 0,
                zIndex: 999,
                height: "85vh",
                overflow: "auto",
                overflowX: "hidden",
                marginTop: 23
            }}
            >
            {videoComments?.map((comment) => (
                <Alert
                ref={nodeRef}
                style={{
                    width: "fit-content",
                    fontFamily: '"Hachi Maru Pop", serif',
                    fontSize: 10,
                    opacity: "0.8",
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginBottom: 3
                }}
                // dismissible
                onClose={() => setShowMessage(false)}
                >
                <Alert.Heading style={{fontSize: 18}}>
                    <div className="column d-flex">
                    <div className="column">{comment.username}</div>

                    {user?.id === comment?.user.replaceAll("-", "") && (
                        <div
                        className="column d-flex justify-content-end align-items-center"
                        style={{ width: "100%" }}
                        >
                        {/* <svg
                                        className="bi bi-pencil"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1em"
                                        height="1em"
                                        fill="currentColor"
                                        viewBox="0 0 16 16"
                                        style={{ fontSize: 17, cursor: "pointer", marginLeft: 20,marginRight: 14 }}
                                        onClick={() =>
                                            comment.id === commentUnderEditing
                                            ? setCommentUnderEditing("")
                                            : setCommentUnderEditing(comment.id)
                                        }
                                        >
                                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                        </svg> */}
                        <svg
                            className="bi bi-x-lg"
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                            style={{
                            fontSize: 17,
                            cursor: "pointer",
                            marginLeft: 40,
                            }}
                            onClick={() => handleDeleteComment(comment.id)}
                        >
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                        </svg>
                        </div>
                    )}
                    </div>
                </Alert.Heading>
                <p style={{fontSize: 13}}>{comment.body}</p>
                </Alert>
            ))}
            </div>
        </CSSTransition>

        {showComments && (
            <div
            className="col d-flex flex-row justify-content-start"
            id="comment-input"
            style={{
                height: "60px",
                // width: "500px",
                position: "fixed",
                bottom: 0,
                zIndex: 999,
                marginBottom: "12.5vh",
                background: "#CFE2FF",
            }}
            >
            <input
                type="text"
                style={{
                width: "100%",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderWidth: 1,
                }}
                onChange={(e) => setComment(e.target.value)}
                value={comment}
            />
            <button
                className="btn btn-primary"
                type="button"
                style={{
                fontFamily: '"Hachi Maru Pop", serif',
                fontSize: 14,
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,
                }}
                onClick={onCommentSubmit}
            >
                comment
            </button>
            </div>
        )}
        </Container>
    );
    }

    export default CommentsPanel;
