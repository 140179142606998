import Navbarx from "./Navbar";

function TermsOfUse(){

    return(
        <>
        <Navbarx />
        <div
        className="container"
        id="termsofuse-container"
        style={{
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",
        marginTop: 100,
        width: "100%",
        borderWidth: 2,
        borderColor: "dark",
        borderStyle: "solid",
        borderRadius: 5,
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 30,
        paddingRight: 30
        }}>
            <h2 style={{textAlign: "center"}}>Terms of Use for Meowtok</h2>
            <text>Welcome to Meowtok! By accessing or using our website, meowtok.com, or the Meowtok mobile application (collectively referred to as "Meowtok"), you agree to the following Terms of Use. Please read them carefully as they govern your use of our services.</text>
            <hr style={{height: 2, backgroundColor: "black", width: "100%", marginTop: 20, marginBottom: 20}}/>
            <h4>1. Acceptance of Terms</h4>
            <text>By using Meowtok, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use. If you do not agree, please discontinue your use of Meowtok immediately.</text>
            <hr style={{height: 2, backgroundColor: "black", width: "100%", marginTop: 20, marginBottom: 20}}/>
            <h4>2. Prohibited Activities</h4>
            <text>When using Meowtok, you agree not to:</text>
                <ol>
                    <li>Upload Copyright-Restricted Content:</li>
                        <ul>
                            <li>You are prohibited from uploading, sharing, or distributing any content that infringes upon the intellectual property rights of others, including copyrighted materials.</li>
                        </ul>
                    <li>Upload Age-Restricted Content:</li>
                        <ul>
                            <li>You may not upload content that is age-restricted, including but not limited to explicit, violent, or otherwise inappropriate material, as determined by applicable laws and community standards.</li>
                        </ul>  
                    <li>Threaten or Harass Other Users:</li>
                        <ul>
                            <li>Harassment, bullying, threats, or any form of abusive behavior toward other Meowtok users is strictly prohibited.</li>
                        </ul>  
                    <li>Impersonate Another Person:</li>
                    <ul>
                        <li>You must not falsely represent yourself as someone else, including public figures or other users, or use Meowtok in a deceptive or misleading manner.</li>
                    </ul>  
                </ol> 
            <hr style={{height: 2, backgroundColor: "black", width: "100%", marginTop: 20, marginBottom: 20}}/>
            <h4>3. Content Responsibility</h4>
            <text>You are solely responsible for the content you upload, post, or share on Meowtok. We reserve the right to remove any content that violates these Terms of Use or applicable laws.</text>
            <hr style={{height: 2, backgroundColor: "black", width: "100%", marginTop: 20, marginBottom: 20}}/>
            <h4>4. Account Termination</h4>
            <text>Violation of these terms may result in the suspension or termination of your account without prior notice.</text>
            <hr style={{height: 2, backgroundColor: "black", width: "100%", marginTop: 20, marginBottom: 20}}/>
            <h4>5. Updates to Terms</h4>
            <text>Meowtok reserves the right to update or modify these Terms of Use at any time. Changes will take effect immediately upon posting. Your continued use of Meowtok constitutes acceptance of the revised terms.</text>
            <hr style={{height: 2, backgroundColor: "black", width: "100%", marginTop: 20, marginBottom: 20}}/>
            <h4>6. Contact Us</h4>
            <text>For any questions or concerns about these Terms of Use, please contact us at support@meowtok.com.</text>
            <text>Thank you for being a part of the Meowtok community! Enjoy responsibly.</text>
        </div>

        <div
        style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center"
        }}>
            <small
            style={{
            fontFamily: '"Hachi Maru Pop", serif',
            marginTop: 30,
            marginBottom: 30,
            }}>
                MeowTok©, 2024
            </small>
        </div>

        </>
    )

}

export default TermsOfUse;