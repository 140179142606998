import SortbyReducer from "./SortbyReducer";
import OffcanvasReducer from "./OffcanvasReducer";
import UserSearchReducer from "./UserSearchReducer";
import { combineReducers } from "redux";

const AllReducer = combineReducers({
    offcanvasToggle: OffcanvasReducer,
    sortby: SortbyReducer,
    search: UserSearchReducer,
})
export default AllReducer;