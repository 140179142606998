import RegisterForm from "../componenets/RegisterForm";
import Navbarx from "../componenets/Navbar";
import Offcanvasx from "../componenets/Offcanvas";


function Register(){
    return(
    
    <div className="container-fluid d-flex flex-row justify-content-center align-items-center p-0">
        <Navbarx />
        <RegisterForm/>
        <Offcanvasx/>
    </div>
    
)
}

export default Register;