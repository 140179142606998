import VideoStream from "../componenets/VideoStream";
import { useParams, useLocation } from "react-router-dom";



function Videos(){
    const { videoStream } = useParams();
    const location = useLocation();


    if (videoStream === "explore")
        return(
            <VideoStream
            fetchAPI={process.env.REACT_APP_BASE_URL + "/video/explore"}
            numItemsInit={10}
            startIndex = {0}
            loadedItems ={[]}
            require_login = {false}
            />
        )


        else if (videoStream === "following")
            return(
                <VideoStream
                fetchAPI={process.env.REACT_APP_BASE_URL + "/video/following_videos"}
                numItemsInit={10}
                startIndex = {0}
                loadedItems ={[]}
                require_login = {true}
                />
            )


    else if (videoStream === "home"){

                try{
                    if(location.state.sortbyState === "newest")
                        {
                            return(
                                <VideoStream
                                fetchAPI={process.env.REACT_APP_BASE_URL + "/video/sort_by/newest"}
                                numItemsInit={10}
                                startIndex = {location.state.index}
                                loadedItems={location.state.items}
                                require_login = {false}
                                />)}
                    else if(location.state.sortbyState === "likes")
                        {
                            return(
                                <VideoStream
                                fetchAPI={process.env.REACT_APP_BASE_URL + "/video/sort_by/likes"}
                                numItemsInit={10}
                                startIndex = {location.state.index}
                                loadedItems={location.state.items}
                                require_login = {false}
                                />)}
                    else if(location.state.sortbyState === "views")
                        {
                            return(
                                <VideoStream
                                fetchAPI={process.env.REACT_APP_BASE_URL + "/video/sort_by/views"}
                                numItemsInit={10}
                                startIndex = {location.state.index}
                                loadedItems={location.state.items}
                                require_login = {false}
                                />)}
                    else if(location.state.sortbyState === "tag")
                        {
                            return(
                                <VideoStream
                                fetchAPI={process.env.REACT_APP_BASE_URL + `/video/sort_by/tag/${location.state.tag}`}
                                numItemsInit={10}
                                startIndex = {location.state.index}
                                loadedItems={location.state.items}
                                require_login = {false}
                                />)}
                        }
                catch{}
                }
    else if (videoStream === "user"){
        return(
            <VideoStream
            fetchAPI={process.env.REACT_APP_BASE_URL + `/user/videos/${location.state.user}`}
            numItemsInit={10}
            startIndex = {location.state.index}
            loadedItems={location.state.items}
            require_login = {false}
            />)
    }

    else
    return(<></>)

}

export default Videos;