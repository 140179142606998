import Offcanvasx from "../componenets/Offcanvas";
import Sidebar from "../componenets/Sidebar";
import Navbarx from "../componenets/Navbar";
import VideoArea from "../componenets/VideoArea";
import {useSelector} from 'react-redux'
import { useState, useEffect } from 'react' 
import { useParams } from "react-router-dom";
import { Container, Button, Alert } from "react-bootstrap";

function Home(){
    const sortbyState = useSelector(state => state.sortby)
    const { sortBy } = useParams();
    const { tag } = useParams();

    useEffect(() => {
        if(sortbyState?.sortby === "tag"){
            window.location.href = `/tag/${sortbyState?.tag}`}
        }, [sortbyState]);

        if(sortBy === "likes"){
            return(
                <div className="container-fluid d-flex flex-row justify-content-start align-items-start" style={{backgroundColor: "#fcf5e1"}}>
                    <Navbarx />
                    <Offcanvasx />
                        <VideoArea 
                        fetchAPI={process.env.REACT_APP_BASE_URL + "/video/sort_by/likes/"} 
                        numItemsInit={10}
                        numItemsOnScroll={10}
                        userVideoArea={false}
                        sortbyState= {sortbyState}/>
                    <Sidebar activeSort={"likes"}/>
                </div>
            )
        }
        else if(sortBy === "views"){
            return(
                <div className="container-fluid d-flex flex-row justify-content-start align-items-start" style={{backgroundColor: "#fcf5e1"}}>
                    <Navbarx />
                    <Offcanvasx />
                        <VideoArea 
                        fetchAPI={process.env.REACT_APP_BASE_URL + "/video/sort_by/views/"} 
                        numItemsInit={10}
                        numItemsOnScroll={10}
                        userVideoArea={false}
                        sortbyState= {sortbyState}/>
                    <Sidebar activeSort={"views"}/>
                </div>
            )
        }
        else if(window.location.pathname.startsWith('/tag/')){
            return(
                <div className="container-fluid d-flex flex-row justify-content-start align-items-start" style={{backgroundColor: "#fcf5e1"}}>
                    <Navbarx />
                    <Offcanvasx />
                        <VideoArea 
                        fetchAPI={process.env.REACT_APP_BASE_URL + `/video/sort_by/tag/${tag}`}
                        numItemsInit={10}
                        numItemsOnScroll={10}
                        userVideoArea={false}
                        sortbyState= {sortbyState}/>
                    <Sidebar activeSort={"tag"} tag ={tag}/>
                </div>
            )
        }
        else{
            return(
                <div className="container-fluid d-flex flex-row justify-content-start align-items-start" style={{backgroundColor: "#fcf5e1"}}>
                    <Navbarx />
                    <Offcanvasx />
                        <VideoArea 
                        fetchAPI={process.env.REACT_APP_BASE_URL + "/video/sort_by/newest/"} 
                        numItemsInit={10}
                        numItemsOnScroll={10}
                        userVideoArea={false}
                        sortbyState= {sortbyState}/>
                    <Sidebar activeSort={"newest"}/>
                </div>
            )
        }
}

export default Home;