import React, { useState } from "react";
import axios from "axios";
import Toaster from "./Toaster";
import '../css/stripe.css'
import Modal from 'react-bootstrap/Modal';


const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function PasswordResetForm(props) {
const { username, profile_pic, token } = props;

const [form, setForm] = useState({
    password: "",
    passwordConf: "",
});
const [passwordError, setPasswordError] = useState(null);
const [showModal, setShowModal] = useState(false);
const [resetSuccess, setResetSuccess] = useState(false);

const handleModalClose = () => setShowModal(false);

const handleFormSubmit = (event) => {

    event.preventDefault();
    setPasswordError("");


    if (resetSuccess){
        window.location.href = "/login/";
        return;}

    if (form.password !== form.passwordConf) {
    setPasswordError(["The two passwords must be the same"]);
    return;
    }
    axios
    .post(process.env.REACT_APP_BASE_URL + "/auth/password_reset/confirm", {
        token: token,
        password: form.password,
    })
    .then((res) => {
        setShowModal(true)
        setResetSuccess(true)
    })
    .catch((err) => setPasswordError(err.response.data?.password));
};

const [toaster, setToaster] = useState({
    title: "",
    show: false,
    message: "",
    type: "",
});

return (
    <>
    <div
    id="password-reset-container"
    className="container shadow-lg p-5 d-flex flex-column justify-content-center align-items-center"
    style={{ paddingRight: 0, paddingLeft: 0}}
    >
    <div className="row d-flex flex-column justify-content-center align-items-center"
    style={{width: "100%"}}>
        {
        profile_pic && (
            <div className="col d-flex flex-column justify-content-center align-items-center">
                <img
                id="profile-image"
                className="rounded-circle"
                width="120px"
                height="120px"
                style={{
                margin: 0,
                width: 90,
                height: 90,
                background: "white",
                }}
                src={profile_pic?.substring(0, profile_pic.lastIndexOf("?"))}/>
            </div>
        )}

        <div className="col d-flex flex-row justify-content-center align-items-center">
        <small style={{ fontFamily: '"Hachi Maru Pop", serif', marginTop: 10, marginBottom: 20}}>
            {username}
        </small>
        </div>
    </div>

        <form id="password-reset-form" onSubmit={handleFormSubmit} >
        <div
            className="col"
            style={{ width: "100%" }}
        >
            <small
            className="d-flex justify-content-center"
            style={{
                fontFamily: '"Hachi Maru Pop", serif',
                width: "100%",
                fontWeight: "bold",
            }}
            >
            Password Reset
            </small>
        </div>
        <div
            className="col d-flex justify-content-center align-items-center"
            style={{ width: "100%" }}
        >
            <input
            className="border rounded-0 border-2 border-dark form-control"
            id="enter-email-input"
            type="password"
            style={{ marginTop: 26, width: "100%", textAlign: "center" }}
            placeholder="New Password"
            onChange={(e) => setForm({ ...form, password: e.target.value })}
            />
        </div>
        <div
            className="col d-flex justify-content-center align-items-center"
            style={{ width: "100%" }}
        >
            <input
            className="border rounded-0 border-2 border-dark form-control"
            id="enter-email-input"
            type="password"
            placeholder="Repeat New Password"
            style={{ marginTop: 29, width: "100%", textAlign: "center" }}
            onChange={(e) =>
                setForm({ ...form, passwordConf: e.target.value })
            }
            />
        </div>
        <div
            className="col d-flex flex-column justify-content-center align-items-stretch"
            style={{ marginTop: 29, width: "100%" }}
        >
            <button
            className="btn btn-primary border border-2 border-dark rounded-0"
            type="submit"
            style={{ width: "100%", fontFamily: '"Hachi Maru Pop", serif' }}
            >
            Change
            </button>
        </div>
        {/* <small className="text-danger">{passwordError}</small> */}
        {passwordError && (
            <ul>
            {passwordError.map((error) => {
                return <li className="text-danger">{error}</li>;
            })}
            </ul>
        )}
        </form>






    <Modal
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    show={showModal}
    onHide={handleModalClose} animation={false}>
    <div className="modal-dialog modal-dialog-centered border border-3 border-dark" role="html"
    style={{
        width: "100%",
        height: "100%",
        marginTop: 0,
        marginBottom: 0,
        padding: 0,
        margin: 0,
    }}>
    <div className="modal-content"
    style={{
    borderRadius: 0,
    background: "#fcf5e1"}}>
    <div className="d-flex flex-row justify-content-center">
            <div className="col-11 col d-flex flex-column justify-content-center align-items-center"
            style={{marginTop: 10}}>
                <h5 style={{fontFamily: '"Hachi Maru Pop", serif', fontWeight: "bold"}}>Success!</h5>
            </div>
            <div className="col d-flex flex-row justify-content-end">
                <button
                onClick={handleModalClose}
                style={{
                background: "transparent",
                border: "none"}}>
                    <svg
                    className="bi bi-x-lg"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    style={{ marginRight: 8, fontSize: 30, marginTop: 10}}>
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                    </svg>
                </button>
            </div>
        </div>
        <div className="modal-body">
            <p style={{ fontFamily: '"Hachi Maru Pop", serif' }}>
            Your password has been changed successfully.
            </p>
        </div>
        <div className="d-flex flex-row justify-content-end align-itmes-center">
        <button
            className="btn btn-primary border border-2 border-dark rounded-0"
            type="button"
            style={{ fontFamily: '"Hachi Maru Pop", serif', marginRight: 20, marginBottom: 10 }}
            onClick={handleModalClose}
        >
            close
        </button>
        </div>
        </div>
    </div>
    </Modal>












    <Toaster
        title={toaster.title}
        message={toaster.message}
        type={toaster.type}
        showToast={toaster.show}
        onClose={() => setToaster({ ...toaster, show: false })}
        delay={toaster.delay}
    />
    </div>

    
    <small className="d-flex flex-row justify-content-center align-items-center"
    style={{
        fontFamily: '"Hachi Maru Pop", serif',
        marginTop: "1vh",
        marginBottom: 0,
        height: 100
    }}
    >
    MeowTok©, 2024
    </small>
</>
);
}

export default PasswordResetForm;
