const OffcanvasReducer =
    (state = true, action) => {
        switch (action.type) {
            case "OFFCANVAS_ON":
                return true
                
            case "OFFCANVAS_OFF":
                return false
            default:
                return state;
        }
    };
export default OffcanvasReducer;


