import React, { useState } from "react";
import { useUserActions } from "../hooks/user.actions";
import '../css/stripe.css'


function RegisterForm(){

    const [error, setError] = useState(null)
    const [usernameError, setUsernamError] = useState(null)
    const [emailError, setEmailError] = useState(null)
    const [passwordError, setPasswordError] = useState(null)




    const [form, setForm] = useState({
        username: "",
        email: "",
        password: "",
        passwordConf: "",
        });

    const userActions = useUserActions();

    const handleSubmit = (event) => {
        event.preventDefault();

        setError(null)
        setUsernamError(null)
        setEmailError(null)
        setPasswordError(null)

        if (form.password !== form.passwordConf){
            setPasswordError(["The two passwords must be the same"])
            return
        }
    
    
        const data = {
            username: form.username,
            password: form.password,
            email: form.email,
        };
    
        userActions.register(data).catch((err) => {
            if(err.message){
                try{
                        const errMessage = JSON.parse(err.request.response);
                        console.log("errMessage: ", errMessage)
                        if (errMessage["detail"]){
                            setError(errMessage["detail"])
                        }
                        if (errMessage["email"]){
                            setEmailError(errMessage["email"])
                        }
                        if (errMessage["password"]){
                            setPasswordError(errMessage["password"])
                        }
                        if (errMessage["username"]){
                            setUsernamError(errMessage["username"])
                        }
                        console.log("passwordError: ", errMessage["password"][0])
                        console.log("typeof", typeof(errMessage["password"][0]))
                    }
                    catch(err){}
                // console.log("errMessage: ", err.message)
            }
        });
        };


    return(
        <div id="stripe" className="d-flex flex-column align-items-center" style={{width: "100%"}}>
            <div id="register-form-lg" 
            className="row
            d-flex flex-column
            justify-content-sm-start align-items-sm-center
            flex-md-column justify-content-md-start align-items-md-center
            flex-lg-row justify-content-lg-center align-items-lg-start
            py-5 px-0 shadow-lg"
            style={{ background: "#fcf5e1" }}
            >
                <div
                className="
                col-12
                col-lg-5
                col-5
                d-flex flex-column
                align-items-center jutify-content-center" 
                // style={{background: "green"}}
                >
                <form
                    className="d-flex flex-column align-items-center justify-content-center"
                    onSubmit={handleSubmit}
                    style={{ marginRight: 0, marginLeft: 0 }}
                >
                    <label
                    id="username-label"
                    className="form-label"
                    style={{
                    fontFamily: '"Hachi Maru Pop", serif',
                    fontSize: 14,
                    width: "100%"}}>
                        User name
                    </label>

                    <input
                    className="border rounded-0 border-2 border-dark form-control"
                    type="text"
                    onChange={(e) => setForm({ ...form, username: e.target.value })}
                    style={{ width: 236, marginLeft: 7 }}
                    required
                    />
                    {
                        usernameError && 
                        <div style={{ marginTop: 0, width: "100%" }}>
                        <small>
                            <span style={{ color: "rgb(255, 0, 0)" }}>
                            <ul><li>{usernameError}</li></ul>
                            </span>
                        </small>
                        </div>
                    }

                    <label
                    id="email-label"
                    className="form-label"
                    style={{
                        marginTop: 14,
                        fontFamily: '"Hachi Maru Pop", serif',
                        fontSize: 14,
                        width: "100%"
                    }}
                    >
                    Email
                    </label>
                    <input
                    className="border rounded-0 border-2 border-dark form-control"
                    type="email"
                    onChange={(e) => setForm({ ...form, email: e.target.value })}
                    style={{ width: 236, marginRight: 0, marginLeft: 7 }}
                    required
                    />
                    {
                        emailError && 
                        <div style={{ marginTop: 0, width: "100%"}}>
                        <small>
                            <span style={{ color: "rgb(255, 0, 0)" }}>
                            <ul><li>{emailError}</li></ul>
                            </span>
                        </small>
                        </div>
                    }

                    <label
                    id="password-label"
                    className="form-label"
                    style={{
                        marginTop: 37,
                        fontFamily: '"Hachi Maru Pop", serif',
                        fontSize: 14,
                        width: "100%"
                    }}
                    >
                    Password
                    </label>
                    <input
                    className="border rounded-0 border-2 border-dark form-control"
                    type="password"
                    onChange={(e) => setForm({ ...form, password: e.target.value })}
                    style={{ width: 236, marginLeft: 7 }}
                    required
                    />

                    {
                        passwordError && 
                        <div style={{ marginTop: 0, width: "250px"}}>
                        <small>
                            <span style={{ color: "rgb(255, 0, 0)" }}>
                            <ul>
                            {passwordError.map((err) => { return<li>{err}<br/></li> })}
                            </ul>
                            </span>
                        </small>
                        </div>
                    }



                    <label
                    className="form-label"
                    style={{
                        fontFamily: '"Hachi Maru Pop", serif',
                        fontSize: 14,
                        paddingTop: 12,
                        width: "100%"
                    }}
                    >
                    Confirm Password
                    </label>
                    <input
                    className="border rounded-0 border-2 border-dark form-control"
                    type="password"
                    onChange={(e) => setForm({ ...form, passwordConf: e.target.value })}
                    style={{ width: 236, marginLeft: 7 }}
                    required
                    />



                    <button
                    className="btn btn-primary text-center border border-2 border-dark rounded-0"
                    type="submit"
                    style={{
                        marginTop: 60,
                        fontFamily: '"Hachi Maru Pop", serif',
                        width: "96%"
                        }}
                    >
                    Sign up
                    </button>
                    {
                        error && 
                        <div style={{ marginTop: 0, width: "100%"}}>
                        <small>
                            <span style={{ color: "rgb(255, 0, 0)" }}>
                            {error}
                            </span>
                        </small>
                        </div>
                    }
                </form>
                </div>

                <div className="p-0 my-0 d-none d-lg-flex"
                style={{marginLeft: "5%",background: "black" ,width: 2 , height: "500px" }} />

                
                <div
                className="
                col-lg-5
                d-none
                d-lg-flex flex-lg-column
                justify-content-center align-items-start
                "
                style={{height: "500px", marginLeft: "5%",  marginTop: "-5%"}}
                >
                    <small
                    style={{
                    marginLeft: 0,
                    fontFamily: '"Hachi Maru Pop", serif',
                    fontSize: 28,
                    marginTop: 0,
                    marginBottom: 0,
                    width: 360
                    }}>
                        MeowTok
                    </small>
                    <small
                        style={{
                        marginLeft: 0,
                        fontFamily: '"Hachi Maru Pop", serif',
                        fontSize: 28,
                        marginTop: 0,
                        marginBottom: 0,
                        width: 360}}>
                        A place for cats lovers
                    </small>
                </div>
            </div>

            <small
            style={{
            fontFamily: '"Hachi Maru Pop", serif',
            marginTop: "4vh",
            marginBottom: 0,
            height: 100}}>
                MeowTok©, 2024
            </small>

        </div>
    )
};

export default RegisterForm;

