import VideoCard from "../componenets/VideoCard";
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";



function sleep(ms) {
return new Promise((resolve) => setTimeout(resolve, ms));
}

function VideoArea(props) {


const {
    fetchAPI,
    numItemsInit,
    numItemsOnScroll,
    userVideoArea,
    sortbyState,
    tag,
} = props;




const [items, setItems] = useState([]);
const [isLoading, setIsLoading] = useState(false);
const [index, setIndex] = useState(numItemsInit);

const fetchData = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);
    axios
    .get(fetchAPI + `?limit=${numItemsOnScroll}&offset=${index}&page=1`)
    .then((res) => {
        setItems((prevItems) => [...prevItems, ...res.data.results]);
        setIndex((prevIndex) => prevIndex + numItemsOnScroll);
        setIsLoading(false);
    })
    .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setIndex((prevIndex) => prevIndex + numItemsOnScroll);
    });
}, [index, isLoading]);

useEffect(() => {
    const getData = async () => {
    setIsLoading(true);
    try {
        const response = await axios.get(
        fetchAPI + `?limit=${numItemsInit}&offset=0&page=1`
        );
        setItems(response.data.results);
    } catch (error) {
        console.log(error);
    }
    setIsLoading(false);
    };

    getData();
}, []);

useEffect(() => {
    let fetching = false;
    const handleScroll = async () => {
    const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 100 && !fetching) {
        fetching = true;
        await fetchData();
        fetching = false;
    }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
    window.removeEventListener("scroll", handleScroll);
    };
}, [fetchData]);


if (userVideoArea) {
    return (
    <div
        className="col-9
                            d-flex flex-row flex-wrap
                            justify-content-center align-items-start"
        style={{ marginTop: 60 }}
    >
        <row className="row d-flex flex-row justify-content-xxl-start align-items-xxl-start">
        <column className="col d-flex flex-row justify-content-center align-items-center flex-wrap justify-content-xxl-center align-items-xxl-center">
            {items.map((item, index) => (
            <div>
                <VideoCard
                video_source={
                    process.env.REACT_APP_S3_BUCKET_URL + item.video_path_on_s3
                }
                video_source_webm={
                    process.env.REACT_APP_S3_BUCKET_URL + item.video_path_on_s3_webm
                }
                
                title={item.title}
                tags={item.tags}
                user_name={item.uploaded_by["username"]}
                user_pic={item.uploaded_by["profile_pic"]}
                user_id={item.uploaded_by["id"]}
                num_likes={item.likes}
                num_views={item.views}
                items={items}
                index={index}
                sortbyState={"from_user_page"}
                tag={tag}
                />
            </div>
            ))}
        </column>
        </row>

        <row
        className="text-center d-flex flex-column justify-content-center align-items-center"
        style={{
            width: "100%",
            marginLeft: 0,
            background: "rgba(255,0,0,0)",
            marginRight: 0,
            marginBottom: 150,
            marginTop: 50,
        }}
        >
        <column
            className="text-center d-flex flex-column justify-content-xxl-center align-items-xxl-center"
            style={{ width: "100%" }}
        >
            <div
            className="hr"
            style={{ width: "100%", height: 1, background: "#000000" }}
            />
            <small
            style={{
                fontFamily: '"Hachi Maru Pop", serif',
                marginTop: 27,
                marginBottom: 0,
            }}
            >
            MeowTok©, 2024
            </small>
        </column>
        </row>
    </div>
    );
} else {
    return (
    <div
        className="col-12 col-sm-12 col-md-12
                            col-lg-9 col-xl-9 col-xxl-9
                            d-flex flex-row flex-wrap
                            justify-content-center align-items-start"
        id="video-area-column"
        style={{
        marginTop: 111,
        // paddingLeft: 53,
        }}
    >
        <row className="row d-flex flex-row justify-content-xxl-start align-items-xxl-start">
        <column className="col d-flex flex-row justify-content-center align-items-center flex-wrap justify-content-xxl-center align-items-xxl-center">
            {items.map((item, index) => (
            <div>
                <VideoCard
                video_source={
                    process.env.REACT_APP_S3_BUCKET_URL + item.video_path_on_s3
                }
                video_source_webm={
                    process.env.REACT_APP_S3_BUCKET_URL + item.video_path_on_s3_webm
                }
                title={item.title}
                tags={item.tags}
                loadingFromUserPage={false}
                user_name={item.uploaded_by["username"]}
                user_pic={item.uploaded_by["profile_pic"]}
                user_id={item.uploaded_by["id"]}
                num_likes={item.likes}
                num_views={item.views}
                items={items}
                index={index}
                sortbyState={sortbyState}
                tag={tag}
                />
            </div>
            ))}
        </column>
        </row>

        <row
        className="text-center d-flex flex-column justify-content-center align-items-center"
        style={{
            width: "100%",
            marginLeft: 0,
            background: "rgba(255,0,0,0)",
            marginRight: 0,
            marginBottom: 150,
            marginTop: 50,
        }}
        >
        <column
            className="text-center d-flex flex-column justify-content-xxl-center align-items-xxl-center"
            style={{ width: "100%" }}
        >
            {items?.length === 0 && <div style={{ height: "50vh" }} />}

            <div
            className="hr"
            style={{ width: "100%", height: 2, background: "#000000" }}
            />
            <small
            style={{
                fontFamily: '"Hachi Maru Pop", serif',
                marginTop: 27,
                marginBottom: 0,
            }}
            >
            MeowTok©, 2024
            </small>
        </column>
        </row>
    </div>
    );
}
}

export default VideoArea;

