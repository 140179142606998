    import Offcanvas from "react-bootstrap/Offcanvas";
    import { useEffect, useState } from "react";
    import { useSelector, useDispatch } from "react-redux";
    import { toggleOffcanvasOff } from "../actions";
    import { getUser } from "../hooks/user.actions";
    import axios from "axios";
    import { setSrotbytag } from "../actions";
    import { useUserActions } from "../hooks/user.actions";
    import { Link } from "react-router-dom";
    import Nav from 'react-bootstrap/Nav';
    import Dropdown from 'react-bootstrap/Dropdown';
    import Image from 'react-bootstrap/Image';

    import Tooltip from 'react-bootstrap/Tooltip';
    import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

    function useMediaQuery(query) {
    const [matches, setMatches] = useState(false);
    const dispatch = useDispatch();
    useEffect(
        () => {
        const mediaQuery = window.matchMedia(query);
        setMatches(mediaQuery.matches);
        const handler = (event) => setMatches(event.matches);
        mediaQuery.addEventListener("change", handler);
        return () => mediaQuery.removeEventListener("change", handler);
        },
        [] // Empty array ensures effect is only run on mount and unmount
    );
    if (!matches) {
        dispatch(toggleOffcanvasOff());
    }
    return matches;
    }

    function Offcanvasx() {

    const dispatch = useDispatch();
    const toggleState = useSelector((state) => state.offcanvasToggle);
    const closeOffcanvasOnBP = useMediaQuery("(max-width: 991px)");
    const [tags, setTags] = useState([]);


    const user = getUser();
    const userActions = useUserActions();


    const handleNavigateToUsers = () => {
        window.location = `/users/`;
    };

    const handleNavigateToExplore = () => {
        window.location.href = "/videos/explore/";
    };

    const handleNavigateToFollowing = () => {
        window.location.href = "/videos/following/";
    };


    const handleNavigateToLogin = () => {
        window.location.href = "/login/";
    };


    const handleNavigateToProfile = () => {
        window.location.href = `/user/${user.id}/`
    };


    const handleLogout = () => {
        userActions.logout()
        window.location.href = "/";
    };


    useEffect(() => {
        const getData = async () => {
        try {
            const response = await axios.get(
            process.env.REACT_APP_BASE_URL + "/video/trending_tgs24"
            );
            setTags(response.data.results);
        } catch (error) {
            console.log(error);
        }
        };
        getData();
    }, []);

    const randomInt = (max) => {
        return Math.floor(Math.random() * max) + 4;
    };

    const handleNavigateToUpload = () => {
        window.location.href = "/upload/";
    };


    return (
        <Offcanvas
        id="offcanvas-1"
        className="d-flex d-lg-none d-xl-none d-xxl-none"
        tabIndex={-1}
        placement="end"
        data-bs-scroll="true"
        style={{ width: 200, marginRight: 0, backgroundColor: "#fcf5e1" }}
        show={toggleState && closeOffcanvasOnBP}
        >
        <div
            className="offcanvas-header d-flex flex-column justify-content-start"
            style={{ paddingRight: 0, paddingLeft: 0, marginRight: 0}}>
            <button
            className="btn-close"
            type="button"
            onClick={() => dispatch(toggleOffcanvasOff())}
            style={{ marginRight: 155 }}
            />

            {user?.id &&
            <Dropdown drop={"down-centered"} >
            <Dropdown.Toggle 
            style={{
            padding: 0,
            border: 0,
            background: "transparent",
            marginTop: 20,
            marginBottom: 10}}>
                <Image
                className="rounded-circle"
                style={{ width: 55, height: 55}}
                src={user?.profile_pic?.substring(0 , user.profile_pic.lastIndexOf("?"))}
                alt={user?.username}
                />
            </Dropdown.Toggle>
            <Dropdown.Menu
            style={{
            borderRadius: 0,
            borderColor: "black",
            borderWidth: "1px",
            fontFamily: '"Hachi Maru Pop", serif',
            background: "#fcf5e1",
            padding: 0
            }}>
                <Dropdown.Item id="drop-item"
                className="border border-2  border-dark d-flex flex-column  justify-content-center"
                style={{fontWeight: "bolder",
                        textAlign: "center",
                        paddingTop: 10,
                        paddingBottom: 10,
                        height: 65
                    }}
                onClick={handleNavigateToProfile}
                >
                    Profile
                </Dropdown.Item>


                <Dropdown.Item id="drop-item"
                className="border border-top-0 border-2  border-dark d-flex flex-column  justify-content-center"
                style={{fontWeight: "bolder",
                        textAlign: "center",
                        paddingTop: 10,
                        paddingBottom: 10,
                        height: 60
                    }}
                    onClick={handleNavigateToUpload}
                >
                Upload
                </Dropdown.Item>


                <Dropdown.Item id="drop-item-logout"
                className="border border-top-0 border-2  border-dark"
                style={{fontWeight: "bolder",
                textAlign: "center",
                marginTop: 0,
                paddingTop: 10,
                paddingBottom: 10}}
                onClick={handleLogout}
                >
                    Logout
                </Dropdown.Item>
            </Dropdown.Menu>
            </Dropdown>
            }

        </div>
        <div
            className="offcanvas-body d-flex flex-column justify-content-start align-items-center"
            style={{
            padding: 19,
            paddingRight: 0,
            paddingLeft: 0,
            paddingBottom: 5,
            paddingTop: 0,
            overflow: "hidden",
            marginRight: 0,}}
                    
                
        >

            <div
            className="row d-flex justify-content-center align-items-center"
            style={{ width: "100%" }}>
            <div
                className="col d-flex flex-column justify-content-center align-items-center justify-content-sm-center align-items-sm-center"
                style={{
                paddingLeft: 0,
                width: "100%",
                paddingRight: 0,
                marginRight: 0,
                }}>
                {!user?.id &&
                    <button
                    className="btn btn-primary"
                    type="button"
                    style={{ width: "60%", marginTop: 50, marginBottom: 10 }}
                    onClick={handleNavigateToLogin}>
                        Login
                    </button>
                }

                <button
                className="btn btn-primary"
                type="button"
                style={{
                width: "60%"}}
                onClick={handleNavigateToExplore}>
                    Explore
                </button>


                {user?.id && 
                    <button
                    className="btn btn-primary"
                    type="button"
                    style={{
                    width: "60%",
                    marginTop: 9,}}
                    onClick={handleNavigateToFollowing}>
                        Following
                    </button>
                }


                <button
                className="btn btn-primary"
                type="button"
                style={{
                width: "60%",
                marginTop: 9,}}
                onClick={handleNavigateToUsers}>
                    Users
                </button>
            </div>
            </div>
            <div
            className="row"
            style={{
                marginTop: 40,
                marginLeft: 0,
                marginRight: 0,
                paddingRight: 0,
            }}
            >
            <div className="col" style={{ marginRight: 0 }}>
                <div className="row" style={{ marginTop: 20 }}>
                <div
                    className="col text-center d-sm-flex justify-content-sm-center align-items-sm-center"
                    style={{ marginRight: 1 }}
                >
                    <small
                    style={{
                        fontFamily: '"Hachi Maru Pop", serif',
                        fontWeight: "bold",
                        fontSize: 17
                    }}
                    >
                    Trending tags
                    </small>
                </div>
                </div>
                <div
                className="row"
                style={{ marginLeft: 0, marginRight: 0, width: "100%" }}
                >
                <div
                className="col-12 d-flex flex-row flex-wrap"
                style={{
                marginTop: 11,
                paddingRight: 0,
                paddingLeft: 0,
                width: "100%",
                marginRight: 0,
                }}>

                    {tags?.map((tag) => (
                        <small
                        className="text-break"
                        id="small-tag"
                        style={{
                        fontFamily: '"Hachi Maru Pop", serif',
                        fontWeight: "bold",
                        borderWidth: 2,
                        borderStyle: "solid",
                        paddingRight: 8,
                        paddingLeft: 8,
                        marginRight: randomInt(15),
                        marginLeft: randomInt(15),
                        marginTop: randomInt(5),
                        cursor: "pointer",
                        }}
                        onClick={() => dispatch(setSrotbytag(tag.name))}>
                            {tag.name}
                        </small>
                    ))}


 
                </div>
                </div>
            </div>
            </div>
            <div
            className="row"
            style={{
            height: "100%",
            marginRight: 0,
            marginLeft: 0}}
            >
            <div
                className="col d-flex flex-column justify-content-end"
                style={{ marginRight: 0 }}
            >


                <Nav.Link
                className="btn btn-primary text-center border border-2 border-dark"
                style={{marginBottom: 10, padding: 5}}
                href="/mobileApp/"
                >
                Mobile App
                </Nav.Link>


                <Nav.Link
                className="btn btn-primary text-center"
                style={{marginBottom: 10, padding: 5}}
                href="/termsOfUse/"
                >
                Terms of use
                </Nav.Link>
            </div>
            </div>
        </div>
        </Offcanvas>
    );
    }

    export default Offcanvasx;
