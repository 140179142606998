import VideoUploadForm from "../componenets/VideoUploadForm";
import Navbarx from "../componenets/Navbar";
import Offcanvasx from "../componenets/Offcanvas";




function Upload() {
  return (
    <div className="container-fluid d-flex flex-column justify-content-md-start align-items-md-center">
      <Navbarx />
      <VideoUploadForm />
      <Offcanvasx />
    </div>
  );
}

export default Upload;
