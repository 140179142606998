const SortbyReducer =
    (state = "newest", action) => {
        switch (action.type) {
            case "SORTBY_NEWEST":
                return "newest"
        
            case "SORTBY_LIKES":
                return "likes"
        
            case "SORTBY_VIEWS":
                return "views"
                
            case "SORTBY_TAG":
                return {sortby: "tag", tag: action.payload}
                
            default:
                return state;
        }
    };

export default SortbyReducer;



