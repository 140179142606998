import React, { useState } from "react";
import { useUserActions } from "../hooks/user.actions";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import '../css/stripe.css'

function LoginForm(){

    const [form, setForm] = useState({
        email: "",
        password: "",
    });
    
    const [error, setError] = useState(null)
    const [emailError, setEmailError] = useState(null)
    const [passwordError, setPasswordError] = useState(null)
    const [showPasswordRest, setShowPasswordRest] = useState(false)
    const [resetEmail, setResetEmail] = useState("")
    const [resetEmailError, setResetEmailError] = useState("")

    const [showModal, setShowModal] = useState(false);
    const handleModalClose = () => setShowModal(false);


    const userActions = useUserActions();

    const handleNavigateSignup = () => {
        window.location.href = `/register/`
    };

    const handleOnShowPWReset = () =>{
        if (showPasswordRest){setShowPasswordRest(false)}
        else{setShowPasswordRest(true)}
        setResetEmailError("")
    }

    const handleOnResetLinkSupmit = (event) => {
        event.preventDefault();
        setResetEmailError("")
        axios.post( process.env.REACT_APP_BASE_URL + "/auth/password_reset/", {email: resetEmail})
        .then(()=> setShowModal(true))
        .catch((err) => setResetEmailError(err.response?.data['email']))
    }



    const handleSubmit = (event) => {
        setError(null)
        setEmailError(null)
        setPasswordError(null)

        event.preventDefault();
        const loginForm = event.currentTarget;

        const data = {
            email: form.email,
            password: form.password,
        };

        userActions.login(data).catch((err) => {
            if(err.message){
                try{
                        const errMessage = JSON.parse(err.request.response);
                        console.log("errMessage: ", errMessage)
        
                        if (errMessage["detail"]){
                            setError(errMessage["detail"])
                        }
        
                        if (errMessage["email"]){
                            setEmailError(errMessage["email"])
                        }
                        if (errMessage["password"]){
                            setPasswordError(errMessage["password"])
                        }
                    }
                    catch(err){}
                console.log("errMessage: ", err.message)
            }
        });
    };


    return(
        
            <div id="stripe" className="d-flex flex-column align-items-center" >

                <div
                id="login-form-row"
                className="row d-flex flex-column
                            justify-content-start align-items-center
                            align-content-stretch flex-sm-column
                            justify-content-sm-start align-items-sm-center
                            flex-md-column justify-content-md-start
                            align-items-md-center flex-lg-row
                            justify-content-lg-center align-items-lg-start
                            flex-xl-row justify-content-xl-center
                            align-items-xl-start flex-xxl-row
                            justify-content-xxl-center align-items-xxl-start
                            p-5 shadow-lg"
                style={{background: "#fcf5e1"}}>
                <div
                    id="login-column-1"
                    className="col-5 p-0 m-0 d-flex flex-column justify-content-start align-items-stretch order-last order-sm-last order-md-last order-lg-first order-xl-first order-xxl-first"
                    
                >


                    

                    <small
                    className="text-center"
                    style={{ fontFamily: '"Hachi Maru Pop", serif',marginTop: 69, fontWeight: "bold" }}
                    >
                    You do not have an account yet?
                    </small>
                    <button
                    className="btn mx-3  btn-primary text-center border border-2 border-dark rounded-0"
                    type="button"
                    style={{ fontFamily: '"Hachi Maru Pop", serif', marginTop: 22 }}
                    onClick={handleNavigateSignup}
                    >
                    Sign up
                    </button>
                </div>
                <div
                    id="login-column-2"
                    className="col-2 d-none d-sm-none d-md-none d-lg-flex d-xl-flex d-xxl-flex flex-row justify-content-center justify-content-xxl-center align-items-xxl-center"
                    style={{ height: "100%" }}
                >
                    <div className="border border-left-2 border-dark" style={{ height: 219, width: 2 }} />
                </div>
                <div
                    id="login-column-3"
                    className="col-5 order-first order-sm-first order-md-first order-lg-last order-xl-last order-xxl-last"
                >
                    <form
                    onSubmit={handleSubmit}
                    >
                    <input
                        className="border rounded-0 border-2 border-dark form-control has-validation"
                        id="enter-email-input"
                        type="email"
                        onChange={(e) => setForm({ ...form, email: e.target.value })}
                        placeholder="Enter your email address"
                        style={{
                        textAlign: "center",
                        fontSize: 15,
                        width: "100%"
                        }}
                        required
                    />
                    {emailError && <div style={{marginTop: 5, color: "red"}}> <small>{emailError}</small> </div>}
                    <input
                        className="border rounded-0 border-2 border-dark form-control"
                        type="password"
                        onChange={(e) => setForm({ ...form, password: e.target.value })}
                        placeholder="Password"
                        style={{
                        textAlign: "center",
                        marginTop: 18,
                        fontFamily: '"Hachi Maru Pop", serif'
                        }}
                        required
                    />
                    {passwordError && <div style={{marginTop: 5, color: "red"}}> <small>{passwordError}</small> </div>}
                    <button
                    className="btn btn-primary text-center border border-2 border-dark rounded-0"
                    type="submit"
                    style={{
                        marginTop: 60,
                        fontFamily: '"Hachi Maru Pop", serif',
                        width: "100%"
                        }}
                    >
                    Login
                    </button>
                    {error && <div className="text-center" style={{marginTop: 10, color: "red"}}> <small>{error}</small> </div>}
                    
                    <p class="mt-4">
                        <a class="link" onClick={handleOnShowPWReset} href="#" style={{fontFamily: '"Hachi Maru Pop", serif', fontSize:15, color: "black", fontWeight: "bold"}}>
                            forgot your password?
                        </a>
                    </p>
                    </form>

                    {showPasswordRest &&
                        <div className=" mt-4">
                        <p style={{fontFamily: '"Hachi Maru Pop", serif', fontSize:13, color: "black"}}>
                            Enter your email address to receive a reset link
                        </p>
                        <form className="d-flex flex-row border border-2 border-dark" onSubmit={handleOnResetLinkSupmit}>
                                <input
                                className="border form-control rounded-0 has-validation"
                                id="enter-email-input"
                                type="email"
                                onChange={(e) => setResetEmail(e.target.value)}
                                placeholder="Enter email"
                                style={{
                                textAlign: "center",
                                fontSize: 14,
                                }}
                                required
                                />
                                <button
                                className="btn btn-primary text-center border rounded-0 "
                                type="submit"
                                style={{
                                    fontFamily: '"Hachi Maru Pop", serif',
                                    fontSize: 13,
                                    width: "50%"
                                    }}
                                >
                                Send link
                                </button>
                        </form>
                        {resetEmailError && <small className="mt-2 text-danger">
                                                {resetEmailError}
                                            </small>
                        }
                    </div>
                    }
                </div>
                </div>


                    <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={showModal}
                    onHide={handleModalClose} animation={false}>
                    <div className="modal-dialog modal-dialog-centered border border-3 border-dark" role="html"
                    style={{
                        width: "100%",
                        height: "100%",
                        marginTop: 0,
                        marginBottom: 0,
                        padding: 0,
                        margin: 0,
                    }}>
                    <div className="modal-content"
                    style={{
                    borderRadius: 0,
                    background: "#fcf5e1"}}>
                    <div className="d-flex flex-row justify-content-center">
                            <div className="col-11 col d-flex flex-column justify-content-center align-items-center"
                            style={{marginTop: 10}}>
                                <h5 style={{fontFamily: '"Hachi Maru Pop", serif', fontWeight: "bold"}}>Email sent!</h5>
                            </div>
                            <div className="col d-flex flex-row justify-content-end">
                                <button
                                onClick={handleModalClose}
                                style={{
                                background: "transparent",
                                border: "none"}}>
                                    <svg
                                    className="bi bi-x-lg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                    style={{ marginRight: 8, fontSize: 30, marginTop: 10}}>
                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <p style={{ fontFamily: '"Hachi Maru Pop", serif' }}>
                            We have sent you an email with a password reset link. Check your spam if you do not find the email in your inbox.
                            </p>
                        </div>
                        <div className="d-flex flex-row justify-content-end align-itmes-center">
                        <button
                            className="btn btn-primary border border-2 border-dark rounded-0"
                            type="button"
                            style={{ fontFamily: '"Hachi Maru Pop", serif', marginRight: 20, marginBottom: 10 }}
                            onClick={handleModalClose}
                        >
                            close
                        </button>
                        </div>
                        </div>
                    </div>
                    </Modal>
                    <small
                    style={{
                        fontFamily: '"Hachi Maru Pop", serif',
                        marginTop: "4vh",
                        marginBottom: 0,
                        height: 100
                    }}
                    >
                    MeowTok©, 2024
                    </small>



            </div>
    )
}



export default LoginForm;