import axios from "axios";
import { useNavigate } from "react-router-dom";
import axiosService from "../helpers/axios";


function useUserActions() {
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_BASE_URL;

  return {
    login,
    register,
    logout,
    edit,
    fetchUsersAxios,
    followAxios,
    folloFromProfilewAxios,
    likeVideoAxios,
    commentoAxios,
    changeUserPassword
  };

  // Login the user
  function login(data) {
    return axios.post(`${baseURL}/auth/login/`, data).then((res) => {
      // Registering the account and tokens in the store
      setUserData(res.data);
      navigate("/");
    });
  }

  // Register the user
  function register(data) {
    return axios.post(`${baseURL}/auth/register/`, data).then((res) => {
      // Registering the account and tokens in the store
      setUserData(res.data);
      navigate("/");
    });
  }

  function fetchUsersAxios(){
    const user = getUser()
    if (user == null){
      return axios
    }
    else{
      return axiosService
    }
  }


  function followAxios(){
    const user = getUser()
    if (user == null){
      return false
    }
    else{
      return axiosService
    }
  }


  function folloFromProfilewAxios(){
    const user = getUser()
    if (user == null){
      return axios
    }
    else{
      return axiosService
    }
  }


  function likeVideoAxios(){
    const user = getUser()
    if (user == null){
      return false
    }
    else{
      return axiosService
    }
  }

  function commentoAxios(){
    const user = getUser()
    if (user == null){
      return false
    }
    else{
      return axiosService
    }
  }

  function changeUserPassword(){
    const user = getUser()
    if (user == null){
      return false
    }
    else{
      return axiosService
    }
  }



  // Edit the user
  function edit(data, userId) {
    console.log("data: ", data)
    return axiosService
      // .patch(`${baseURL}/user/${userId}/`, data, {
        .patch(process.env.REACT_APP_BASE_URL + `/user/update/${userId}/`, data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        // Registering the account in the store
        localStorage.setItem(
          "auth",
          JSON.stringify({
            access: getAccessToken(),
            refresh: getRefreshToken(),
            user: res.data,
          })
        );
      });
  }


  // Logout the user
  function logout() {
    localStorage.removeItem("auth");
    // navigate("/login");
  }
}

// Get the user
function getUser() {
  const auth = JSON.parse(localStorage.getItem("auth")) || null;
  if (auth) {
    return auth.user;
  } else {
    return null;
  }
}




// Get the access token
function getAccessToken() {
  const auth = JSON.parse(localStorage.getItem("auth"));
  return auth?.access;
}

// Get the refresh token
function getRefreshToken() {
  const auth = JSON.parse(localStorage.getItem("auth"));
  return auth?.refresh;
}

// Set the access, token and user property
function setUserData(data) {
  localStorage.setItem(
    "auth",
    JSON.stringify({
      access: data.access,
      refresh: data.refresh,
      user: data.user,
    })
  );
}

export { useUserActions, getUser, getAccessToken, getRefreshToken };
