const UserSearchReducer =
    (state = {bar: false, search: ""}, action) => {
        switch (action.type) {
            case "SEARCHBAR_ON":
                return {bar: true, search: state.search}
        
            case "SEARCHBAR_OFF":
                return {bar: false, search: state.search}

            case "SEARCH":
                return {bar: state.bar, search: action.payload}

                
            default:
                return state;
        }
    };

export default UserSearchReducer;


