export const toggleOffcanvasOn = () => {
    return{
        type: 'OFFCANVAS_ON'
    }
} 

export const toggleOffcanvasOff = () => {
    return{
        type: 'OFFCANVAS_OFF'
    }
} 

export const setSrotbyNewest = () => {
    return{
        type: "SORTBY_NEWEST"
    }
}

export const setSrotbyLikes = () => {
    return{
        type: "SORTBY_LIKES"
    }
}

export const setSrotbyViews = () => {
    return{
        type: "SORTBY_VIEWS"
    }
}


export const setSrotbytag = (tag) => {
    return{
        type: "SORTBY_TAG",
        payload: tag
    }
}



export const toggleSearchBarOn = () => {
    return{
        type: "SEARCHBAR_ON"
    }
}

export const toggleSearchBarOff = () => {
    return{
        type: "SEARCHBAR_OFF"
    }
}


export const setSearchValue = (value) => {
    return{
        type: "SEARCH",
        payload: value
    }
}