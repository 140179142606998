import UserCard from "./UserCard";
import React, { useState, useEffect, useCallback } from "react";
import { getUser } from "../hooks/user.actions";

import { useUserActions } from "../hooks/user.actions";







function UsersAreaSearch(props){


    const { fetchAPI, numItemsInit, numItemsOnScroll } = props;


    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [index, setIndex] = useState(numItemsInit);
    const userActions = useUserActions();

    const user = getUser();



//////////////////Fetch more////////////////////////////////////////
    const fetchData = useCallback(async () => {


    const fetchService = userActions.fetchUsersAxios()
    if (isLoading) return;      
    setIsLoading(true);
    fetchService
        .get( fetchAPI + `?limit=${numItemsOnScroll}&offset=${index}&page=1`)
        .then((res) => {
        setItems((prevItems) => [...prevItems, ...res.data.results]);
        })
        .catch((err) => console.log(err));
    setIndex((prevIndex) => prevIndex + numItemsOnScroll);

    setIsLoading(false);
    }, [index, isLoading, fetchAPI]);
////////////////////////////////////////////////////////////////////////







//////////////////Fetch at the begning////////////////////////////////////////
    useEffect(() => {

    const getData = async () => {
        setIsLoading(true);
        try {
        const fetchService = userActions.fetchUsersAxios()
        const response = await fetchService.get(
            fetchAPI + `?limit=${numItemsInit}&offset=0&page=1`
        );
        setItems(response.data.results);
        } catch (error) {
        console.log(error);
        }
        setIsLoading(false);
    };

    getData();
    }, [fetchAPI]);
////////////////////////////////////////////////////////////////////////








    useEffect(() => {
        let fetching = false;
        const handleScroll = async () => {
            const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
            if (scrollTop + clientHeight >= scrollHeight - 100 && !fetching) {
                fetching = true;
                await fetchData();
                fetching = false;
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [fetchData]);



    return(
            <div className="col-12 
                            d-flex flex-row flex-wrap
                            justify-content-center align-items-start"
                            // style={{marginTop: 111}}
                            >
                {items.map((item) => (
                <div><UserCard
                        username={item.username}
                        profile_pic={item.profile_pic}
                        num_followers={item.num_followers}
                        num_videos={item.num_videos}
                        num_likes={item.num_likes}
                        is_following={item.is_following}
                        user_id={item.id}
                        same_user = {item.id === user?.id}
                        />
                </div>
                ))}
            </div>
    )
}

export default UsersAreaSearch;