import Navbarx from "../componenets/Navbar"
import Carousel from 'react-bootstrap/Carousel';
import {
    useWindowHeight,
  } from '@react-hook/window-size'
import { useState } from "react";
import Nav from 'react-bootstrap/Nav';
import "../css/mobileapp.css"


function MobileApp(){
    const windowHeight = useWindowHeight()
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };


    return (

        <>
        <Navbarx />


        <Carousel data-bs-theme="dark"
        activeIndex={index} onSelect={handleSelect}
        fade
        style={{
        marginTop: 100,
        height: windowHeight - 160,
        display: "flex",
        }}>


        <Carousel.Item
        interval={1000000}
        style={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "flex-start"
        }}>
            <img
            id="app-gif"
            className="border rounded"
            src="https://s3.eu-north-1.amazonaws.com/storage.meowtok.com/app_gifs/loging+or+register.gif"
            alt="First slide"
            // style={{
            // height: "85%",
            // }}
            />
            <Carousel.Caption
            id="gif-discription"
            // style={{
            // height:"15%"}}
            >
            <div
            style={{marginTop: 10}}
            >
            <h5>Login or create an account</h5>
            </div>
            </Carousel.Caption>
        </Carousel.Item>


        <Carousel.Item
        interval={1000000}
        style={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "flex-start"
        }}>
            <img
            id="app-gif"
            className="border rounded"
            src="https://s3.eu-north-1.amazonaws.com/storage.meowtok.com/app_gifs/upload+video.gif"
            alt="Second slide"
            // style={{
            // height: "85%",
            // }}
            />
            <Carousel.Caption
            id="gif-discription"
            // style={{
            // height:"15%"}}
            >
            <div
            style={{marginTop: 10}}>
            <h5>Upload your videos</h5>
            </div>
            </Carousel.Caption>
        </Carousel.Item>


        <Carousel.Item
        interval={1000000}
        style={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "flex-start"
        }}>
            <img
            id="app-gif"
            className="border rounded"
            src="https://s3.eu-north-1.amazonaws.com/storage.meowtok.com/app_gifs/Open+the+app+from+outside+-Slide+left+and+right+.gif"
            alt="Third slide"
            // style={{
            // height: "85%",
            // }}
            />
            <Carousel.Caption
            id="gif-discription"
            // style={{
            // height:"15%"}}
            >
            <div
            style={{marginTop: 10}}>
            <h5>Check what other users have uploaded</h5>
            </div>
            </Carousel.Caption>
        </Carousel.Item>


        <Carousel.Item
        interval={1000000}
        style={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "flex-start"
        }}>
            <img
            id="app-gif"
            className="border rounded"
            src="https://s3.eu-north-1.amazonaws.com/storage.meowtok.com/app_gifs/follow+a+user+and+watch+theri+videos.gif"
            alt="Fourth slide"
            // style={{
            // height: "85%",
            // }}
            />
            <Carousel.Caption
            id="gif-discription"
            // style={{
            // height:"15%"}}
            >
            <div
            style={{marginTop: 10}}>
            <h5>Follow other users and check their content</h5>
            </div>
            </Carousel.Caption>
        </Carousel.Item>


        <Carousel.Item
        interval={1000000}
        style={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "flex-start"
        }}>
            <img
            id="app-gif"
            className="border rounded"
            src="https://s3.eu-north-1.amazonaws.com/storage.meowtok.com/app_gifs/like+and+comment.gif"
            alt="Fourth slide"
            // style={{
            // height: "85%",
            // }}
            />
            <Carousel.Caption
            id="gif-discription"
            // style={{
            // height:"15%"}}
            >
            <div
            style={{marginTop: 10}}>
            <h5>Like and comment</h5>
            </div>
            </Carousel.Caption>
        </Carousel.Item>


        <Carousel.Item
        interval={1000000}
        style={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "flex-start"
        }}>
            <img
            id="app-gif"
            className="border rounded"
            src="https://s3.eu-north-1.amazonaws.com/storage.meowtok.com/app_gifs/settings.gif"
            alt="Fourth slide"
            // style={{
            // height: "85%",
            // }}
            />
            <Carousel.Caption
            id="gif-discription"
            // style={{
            // height:"15%"}}
            >
            <div
            style={{marginTop: 10}}>
            <h5>Customize your experience</h5>
            </div>
            </Carousel.Caption>
        </Carousel.Item>


        <Carousel.Item
        interval={1000000}
        style={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "flex-start"
        }}>
            <img
            id="app-gif"
            className="border rounded"
            src="https://s3.eu-north-1.amazonaws.com/storage.meowtok.com/app_gifs/stream+options.gif"
            alt="Fourth slide"
            // style={{
            // height: "85%",
            // }}
            />
            <Carousel.Caption
            id="gif-discription"
            // style={{
            // height:"15%"}}
            >
            <div
            style={{marginTop: 10}}>
            <h5>Check different video stream options</h5>
            </div>
            </Carousel.Caption>
        </Carousel.Item>


        <Carousel.Item
        interval={1000000}
        style={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "flex-start"
        }}>
            <img
            id="app-gif"
            className="border rounded"
            src="https://s3.eu-north-1.amazonaws.com/storage.meowtok.com/app_gifs/trending.gif"
            alt="Fourth slide"
            // style={{
            // height: "85%",
            // }}
            />
            <Carousel.Caption
            id="gif-discription"
            // style={{
            // height:"15%"}}
            >
            <div
            style={{marginTop: 10}}>
            <h5>Check what tags are trending today</h5>
            </div>
            </Carousel.Caption>
        </Carousel.Item>


        <Carousel.Item
        interval={1000000}
        style={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "flex-start"
        }}>
            <img
            id="app-gif"
            className="border rounded"
            src="https://s3.eu-north-1.amazonaws.com/storage.meowtok.com/app_gifs/users.gif"
            alt="Fourth slide"
            />
            <Carousel.Caption
            id="gif-discription"
            // style={{
            // height:"15%"}}
            >
            <div
            style={{marginTop: 10}}>
            <h5>Search and follow other users</h5>
            </div>
            </Carousel.Caption>
        </Carousel.Item>
        </Carousel>
        <div
        style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center"
        }}>
        <Nav.Link
        id="download-link"
        className="border rounded-0 border-dark text-center"
        style={{
        color: "rgb(0,0,0)",
        display: "flex",
        borderWidth: 1,
        borderColor: "black",
        fontSize: 18,
        height: 50,
        // width: "50%",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center"
        }}
        href="https://s3.eu-north-1.amazonaws.com/storage.meowtok.com/app/211124_2330.apk"
        >
                Download The App (Android)
        </Nav.Link>
        </div>

        </>
    );
}

export default MobileApp;