import Offcanvasx from "../componenets/Offcanvas";
import Navbarx from "../componenets/Navbar";
import UsersArea from "../componenets/UsersArea";
import UsersAreaSearch from "../componenets/UsersAreaSearch";
import { useSelector } from "react-redux";
import '../css/switch.css'
import { useState } from "react";
import { getUser } from "../hooks/user.actions";



function Users(){

const searchBarState = useSelector(state => state.search)
const [switchOnOff, setSwitchOnOff] = useState(false);
const user = getUser();






return(
    <>
        <Navbarx withSearchBar={true} switchOnOff={switchOnOff}/>
        <div 
        className="container-fluid d-flex  flex-column  justify-content-start align-items-center"
        style={{
        marginTop: 99}}>


            <label class="switch" style={{marginBottom: 25}}>
                <input type="checkbox" class="switch-input "/>
                <span
                style={{fontFamily: '"Hachi Maru Pop", serif'}}
                class="switch-label border border-2 border-black"
                data-on="All users"
                data-off="Following"
                onClick={()=> switchOnOff ? setSwitchOnOff(false):setSwitchOnOff(true)}>
                </span>
                <span
                class="switch-handle border border-2 border-black">
                </span>
            </label>


            {(switchOnOff && user?.id) &&
                <UsersArea 
                fetchAPI={process.env.REACT_APP_BASE_URL + "/user/following"}
                numItemsInit={20}
                numItemsOnScroll={20}/>
            }

            {!switchOnOff &&
                (
                    searchBarState.search === '' ?
                    <UsersArea 
                    fetchAPI={process.env.REACT_APP_BASE_URL + "/user/all"}
                    numItemsInit={20}
                    numItemsOnScroll={20}
                    />
                    :
                    <UsersAreaSearch 
                    fetchAPI={process.env.REACT_APP_BASE_URL + `/user/search/${searchBarState.search}/`}
                    numItemsInit={20}
                    numItemsOnScroll={20}
                    />
                )      
            }

            <Offcanvasx />
        </div>
    </>
)
};

export default Users;









// const widthHigherThanSM = useMediaQuery('(max-width: 576px)');

// function useMediaQuery(query) {
//     const [matches, setMatches] = useState(false);

//     useEffect(
//         () => {
//         const mediaQuery = window.matchMedia(query);
//         setMatches(mediaQuery.matches);
//         const handler = (event) => setMatches(event.matches);
//         mediaQuery.addEventListener("change", handler);
//         return () => mediaQuery.removeEventListener("change", handler);
//         },
//       [] // Empty array ensures effect is only run on mount and unmount
//     );
//     return matches;
// }


// const fetchMore = useCallback(async () => {
//     const fetchService = userActions.fetchUsersAxios()
//     if (isLoading) return;      
//     setIsLoading(true);
//     fetchService
//         .get(  process.env.REACT_APP_BASE_URL + `/user/following?limit=10&offset=${index}&page=1`)
//         .then((res) => {
//         setItems((prevItems) => [...prevItems, ...res.data.results]);
//         })
//         .catch((err) => console.log(err));
//     setIndex((prevIndex) => prevIndex + 10);

//     setIsLoading(false);
//     }, [index, isLoading]);





// useEffect(() => {
//     let fetching = false;
//     const handleScroll = async () => {
//         const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
//         if (scrollTop + clientHeight >= scrollHeight - 100 && !fetching) {
//             fetching = true;
//             await fetchMore();
//             fetching = false;
//         }
//     };
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//         window.removeEventListener("scroll", handleScroll);
//     };
// }, [fetchMore]);

