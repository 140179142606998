import React, { useState } from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { useUserActions } from "../hooks/user.actions";


const ImageUpload = () => {

    const [data, setData] = useState({
        title: "",
        description: "",
        profile_pic: "",
    });

    const [errors, setErrors] = useState({
        title: "",
        description: "",
        profile_pic: "",
    });

    const userActions = useUserActions();


    const handleImageChange = (e) => {
        let newData = { ...data };
        newData["profile_pic"] = e.target.files[0];
        setData(newData);
    };

    const doSubmit = async (e) => {
        e.preventDefault();
        userActions.edit(data).catch((err) => console.log("in doSubmit, edit. Err: ", err))
    };


    return (
        <Form>
            <Row>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Control
                        type="file"
                        name="profile_pic"
                        accept="image/jpeg,image/png,image/gif"
                        onChange={(e) => {
                            handleImageChange(e);
                        }}
                    />
                    {errors.profile_pic && (
                        <Form.Text className="alert-danger" tooltip>
                            {errors.profile_pic}
                        </Form.Text>
                    )}
                </Form.Group>
            </Row>
            <Button
                variant="primary"
                type="submit"
                onClick={(e) => doSubmit(e)}>
            </Button>
        </Form>
    );
};

export default ImageUpload;